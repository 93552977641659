<template>
  <b-card title="扫码">
    <b-list-group>
      <b-list-group-item class="d-flex align-items-center">
        <span>1. Cras justo odio</span>
        <feather-icon
          size="22"
          class="del ml-4"
          icon="XIcon"
        />
      </b-list-group-item>
      <b-list-group-item class="d-flex align-items-center">
        <span>2. Dapibus ac facilisis in</span>
        <feather-icon
          size="22"
          class="del ml-4"
          icon="XIcon"
        />
      </b-list-group-item>
    </b-list-group>
    <div class="d-flex justify-content-center mt-4">
      <b-button
        variant="relief-primary"
        @click="$router.push({name:'seatransputnext'})"
      >
        下一步
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BListGroup, BListGroupItem, BButton,
  },
  directives: {
    Ripple,
  },
}
</script>

<style lang="scss" scoped>
.del{
  color: red;
  cursor: pointer;
}
</style>
